<template>
  <div>
    <a-modal
      v-model="visibleModalManagePengajuanCuti"
      class="mod"
      :title="status.isEdit ? 'Form Detail Pengajuan Cuti' : 'Form Tambah Pengajuan Cuti Cuti'"
      @cancel="toggleModalManagePengajuanCuti"
      centered
    >
      <form-pengajuan-cuti
        :disabled="true"
        :dataPengajuanCuti="newPengajuanCuti"
        @handle-change="handleChange"
      />
      <template slot="footer">
        <a-button class="d-none" size="large" key="back" @click="toggleModalManagePengajuanCuti">
          Batal
        </a-button>
        <a-button class="d-none" size="large" key="submit" type="primary" :loading="loadingActionModalManagePengajuanCuti" @click="handleOkModalManagePengajuanCuti">
          {{status.isEdit ? 'Ubah' : 'Tambah'}} Sekarang
        </a-button>
      </template>
    </a-modal>
    <a-modal
      v-model="visibleModalManageKeteranganAdminPengajuanCuti"
      title="Form Penolakan Persetujuan Cuti"
      @cancel="toggleModalManageKeteranganAdminPengajuanCuti"
      centered
    >
      <a-row>
        <a-col :span="24">
        <a-form-item label="Keterangan Admin HR">
          <a-textarea
            v-model="newPengajuanCuti.keterangan_admin"
            :auto-size="{ minRows: 5, maxRows: 7 }"
          />
        </a-form-item>
      </a-col>
      </a-row>
      <template slot="footer">
        <a-button size="large" key="back" @click="toggleModalManageKeteranganAdminPengajuanCuti">
          Batal
        </a-button>
        <a-button size="large" key="submit" type="primary" :loading="loadingActionModalManageKeteranganAdminPengajuanCuti" @click="handleApprovalPengajuanCuti('reject', newPengajuanCuti)">
          Tolak Sekarang
        </a-button>
      </template>
    </a-modal>
    <div class="d-flex align-items center">
      <!-- <div class="ml-auto">
        <a-button @click.prevent="toggleModalManagePengajuanCuti('add')" size="large" type="primary"><a-icon type="plus" />Tambah Cuti</a-button>
      </div> -->
    </div>
    <div class="mt-4">
      <a-table
        :columns="pengajuanCutiColumns"
        :data-source="pengajuanCutiDataTable"
        :pagination="pagination"
        :loading="loadingTable"
        bordered
      >
        <div class="d-flex flex-column" slot="nama" slot-scope="value, record">
          <span class="mb-1 font-weight-bold">{{record.guru.nik}}</span>
          <span class="mb-1 font-weight-bold">{{value}}</span>
          <span class="mb-3">({{moment(record.createdAt).format('DD MMM YYYY HH:mm')}})</span>
          <span>
            <a-tag :color="record.status_pengajuan === 'menunggu' ? 'orange' : record.status_pengajuan === 'tolak' ? 'red' : 'green'">
            {{record.status_pengajuan === 'terima' ? 'DITERIMA' : record.status_pengajuan.toUpperCase() }}
          </a-tag>
          </span>
        </div>
        <div slot="regulasi" slot-scope="value">
          <a-tag :color="value === 'Tidak Dibayar' ? 'red' : 'green'">
            {{value.toUpperCase()}}
          </a-tag>
        </div>
        <div class="d-flex flex-column" slot="aksi" slot-scope="value, record">
          <a-button
            @click.prevent="toggleModalManagePengajuanCuti('edit', record)"
            class="mr-3 text-warning border border-warning w-100"
            size="default"
          >
            <a-icon type="idcard"/>Detail
          </a-button>
          <a-button
            @click.prevent="handleApprovalPengajuanCuti('approve', record)"
            class="mr-3 text-info border border-info w-100 my-3"
            :disabled="record.status_pengajuan === 'terima'"
            size="default"
          >
            <a-icon type="check"/>{{record.status_pengajuan === 'terima' ? 'Disetujui' : 'Setuju'}}
          </a-button>
          <a-button
            @click.prevent="toggleModalManageKeteranganAdminPengajuanCuti('edit', record)"
            class="text-danger border border-danger w-100"
            size="default"
          >
            <a-icon type="close" />{{record.status_pengajuan === 'terima' ? 'Batalkan' : 'Tolak'}}
          </a-button>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
const FormPengajuanCuti = () => import('@/components/app/AdminHR/Cuti/FormPengajuanCuti')
const pengajuanCutiColumns = [
  {
    title: 'Pemohon',
    dataIndex: 'nama',
    key: 'nama',
    width: 270,
    scopedSlots: { customRender: 'nama' },
  },
  {
    title: 'Cuti',
    dataIndex: 'nama_cuti',
    key: 'nama_cuti',
    scopedSlots: { customRender: 'nama_cuti' },
  },
  {
    title: 'Regulasi',
    dataIndex: 'regulasi',
    key: 'regulasi',
    align: 'center',
    scopedSlots: { customRender: 'regulasi' },
  },
  {
    title: 'Periode',
    dataIndex: 'periode',
    key: 'periode',
    align: 'center',
    scopedSlots: { customRender: 'periode' },
    children: [
      {
        title: 'Mulai',
        dataIndex: 'tanggal_awal',
        key: 'tanggal_awal',
        align: 'center',
        scopedSlots: { customRender: 'tanggal_awal' },
      },
      {
        title: 'Selesai',
        dataIndex: 'tanggal_akhir',
        key: 'tanggal_akhir',
        align: 'center',
        scopedSlots: { customRender: 'tanggal_akhir' },
      },
    ],
  },
  {
    title: 'Aksi',
    dataIndex: 'aksi',
    key: 'aksi',
    scopedSlots: { customRender: 'aksi' },
    align: 'center',
  },
]
export default {
  components: {
    FormPengajuanCuti,
  },
  data() {
    return {
      pengajuanCutiColumns,
      pengajuanCutiDataTable: [],
      pagination: {},
      loadingTable: false,
      visibleModalManagePengajuanCuti: false,
      visibleModalManageKeteranganAdminPengajuanCuti: false,
      loadingActionModalManagePengajuanCuti: false,
      loadingActionModalManageKeteranganAdminPengajuanCuti: false,
      status: {
        isEdit: false,
        isCreate: false,
      },
      statusKeteranganAdmin: {
        isEdit: false,
        isCreate: false,
      },
      newPengajuanCuti: {
        id_cuti: null,
        id_pegawai: null,
        tanggal_awal: null,
        tanggal_akhir: null,
        keterangan_tambahan: null,
        keterangan_admin: null,
        files: null,
        cuti: null,
        guru: null,
        createdAt: null,
      },
      idEdit: null,
    }
  },
  methods: {
    moment,
    handleChange(payload) {
      const { value, column } = payload

      this.newPengajuanCuti[column] = value
    },
    toggleModalManageKeteranganAdminPengajuanCuti(status, data) {
      this.visibleModalManageKeteranganAdminPengajuanCuti = !this.visibleModalManageKeteranganAdminPengajuanCuti
      if (!this.visibleModalManageKeteranganAdminPengajuanCuti) {
        setTimeout(() => {
          this.statusKeteranganAdmin = {
            isEdit: false,
            isCreate: false,
          }
          this.newPengajuanCuti = {
            id_cuti: null,
            id_pegawai: null,
            tanggal_awal: null,
            tanggal_akhir: null,
            keterangan_tambahan: null,
            keterangan_admin: null,
            files: null,
            cuti: null,
            guru: null,
          }
          this.idEdit = null
        }, 500)
        return ''
      }

      if (status) {
        this.status[status === 'add' ? 'isCreate' : 'isEdit'] = true
        if (status === 'edit') {
          this.idEdit = data.key
          this.newPengajuanCuti = {
            id_cuti: data.id_cuti,
            id_pegawai: data.id_pegawai,
            tanggal_awal: data.tanggal_awal,
            tanggal_akhir: data.tanggal_akhir,
            keterangan_tambahan: data.keterangan_tambahan,
            keterangan_admin: data.keterangan_admin,
            files: data.files,
            cuti: data.cuti,
            guru: data.guru,
          }
        }
      }
    },
    toggleModalManagePengajuanCuti(status, data) {
      this.visibleModalManagePengajuanCuti = !this.visibleModalManagePengajuanCuti
      if (!this.visibleModalManagePengajuanCuti) {
        setTimeout(() => {
          this.status = {
            isEdit: false,
            isCreate: false,
          }
          this.newPengajuanCuti = {
            id_cuti: null,
            id_pegawai: null,
            tanggal_awal: null,
            tanggal_akhir: null,
            keterangan_tambahan: null,
            keterangan_admin: null,
            files: null,
            cuti: null,
            guru: null,
            createdAt: null,
          }
          this.idEdit = null
        }, 500)
        return ''
      }

      if (status) {
        this.status[status === 'add' ? 'isCreate' : 'isEdit'] = true
        if (status === 'edit') {
          this.idEdit = data.key
          this.newPengajuanCuti = {
            id_cuti: data.id_cuti,
            id_pegawai: data.id_pegawai,
            tanggal_awal: data.tanggal_awal,
            tanggal_akhir: data.tanggal_akhir,
            keterangan_tambahan: data.keterangan_tambahan,
            keterangan_admin: data.keterangan_admin,
            files: data.files,
            cuti: data.cuti,
            guru: data.guru,
            createdAt: data.createdAt,
          }
        }
      }
    },
    handleOkModalManagePengajuanCuti() {
      const content = `${this.status.isEdit ? 'Apakah anda yakin ingin mengubah cuti ini? perubahan ini akan mempengaruhi sistem absensi pegawai' : 'Apakah anda yakin ingin membuat cuti baru ini? perubahan ini akan disimpan kedalam sistem'}`
      if (this.isValidateForm) {
        this.$notification.error({
          message: 'Peringatan',
          description: 'Semua kolom wajib diisi',
        })
      } else {
        this.$confirm({
          title: 'Peringatan',
          content: (
            <div>{content}</div>
          ),
          onOk: () => {
            this.loadingActionModalManagePengajuanCuti = true
            const payload = this.status.isEdit ? {
              id: this.idEdit,
              dataCuti: this.newPengajuanCuti,
            } : {
              dataCuti: this.newPengajuanCuti,
            }
            this.$store.dispatch(`cuti/${this.status.isEdit ? 'EDIT' : 'POST'}_CUTI`, payload)
              .then(isSuccess => {
                this.loadingActionModalManagePengajuanCuti = false
                this.toggleModalManagePengajuanCuti()
                this.fetchDataPengajuanCuti()
                if (isSuccess) {
                  const description = this.status.isEdit ? 'Cuti berhasil diubah' : 'Cuti baru berhasil ditambahkan'
                  this.$notification.success({
                    message: 'Berhasil',
                    description,
                  })
                } else {
                  const description = this.status.isEdit ? 'Cuti gagal diubah' : 'Cuti baru gagal ditambahkan'
                  this.$notification.error({
                    message: 'Gagal',
                    description,
                  })
                }
              })
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.status.isEdit ? 'Ubah Sekarang' : 'Tambah Sekarang',
          cancelText: 'Batal',
        })
      }
    },
    handleApprovalPengajuanCuti(status, data) {
      this.$confirm({
        title: 'Peringatan',
        content: (
          <div>Apakah anda yakin ingin {status === 'approve' ? 'menerima' : 'menolak'} cuti <b>{data.nama}</b> ?</div>
        ),
        onOk: () => {
          this.loadingTable = true
          this.loadingActionModalManageKeteranganAdminPengajuanCuti = true
          this.$store.dispatch(`cuti/${status === 'approve' ? 'APPROVE' : 'REJECT'}_PENGAJUAN_CUTI`, {
            id: status === 'approve' ? data.key : this.idEdit,
            dataPengajuanCuti: this.newPengajuanCuti,
          })
            .then(isSuccess => {
              this.loadingActionModalManageKeteranganAdminPengajuanCuti = false
              if (status === 'reject') this.toggleModalManageKeteranganAdminPengajuanCuti()
              this.fetchDataPengajuanCuti()
              if (isSuccess) {
                this.$notification.success({
                  message: 'Berhasil',
                  description:
                    `Pengajuan berhasil ${status === 'approve' ? 'diterima' : 'ditolak'}`,
                })
              } else {
                this.$notification.error({
                  message: 'Gagal',
                  description: `Pengajuan gagal ${status === 'approve' ? 'diterima' : 'ditolak'}`,
                })
              }
            })
        },
        onCancel: () => {
          this.loadingDeletePeriode = false
        },
        centered: true,
        icon: 'warning',
        okType: status === 'approve' ? 'primary' : 'danger',
        okText: status === 'approve' ? 'Terima Persetujuan Cuti' : 'Tolak Persetujuan Cuti',
        cancelText: 'Batal',
      })
    },
    fetchDataPengajuanCuti(params = { page: 1, order: 'ASC', search: '', type: '', sortBy: '' }) {
      this.loadingTable = true
      this.$store.dispatch('cuti/FETCH_PENGAJUAN_CUTI', { page: params.page, order: params.order, search: params.search, sortBy: params.sortBy })
        .then(res => {
          this.loadingTable = false
          // const pagination = { ...this.pagination }
          // pagination.total = res.total
          // this.pagination = pagination
          this.pengajuanCutiDataTable = res.map(el => {
            return {
              key: el.id,
              id: el.id,
              ...el,
              nama: el.guru.nama,
              nama_cuti: el.cuti.nama,
              regulasi: el.cuti.regulasi,
              tanggal_awal: moment(el.tanggal_awal).format('DD MMM YYYY'),
              tanggal_akhir: moment(el.tanggal_akhir).format('DD MMM YYYY'),
              tanggal_mengajukan: moment(el.tanggal_akhir).format('DD MMM YYYY'),
            }
          })
        })
    },
  },
  mounted() {
    this.fetchDataPengajuanCuti()
  },
  computed: {
    isValidateForm() {
      if (this.newPengajuanCuti.nama && this.newPengajuanCuti.tipe && this.newPengajuanCuti.regulasi) {
        return false
      }
      return true
    },
  },
}
</script>

<style>

</style>
